// export default [
//   "Ansci-Animal Sciences (0118)",
//   "Biochemistry (0084)",
//   "Biochemistry Addition (0205)",
//   "Biotech (0082)",
//   "Biotron (0045)",
//   "Birge (0054)",
//   "Bock Lab (0033)",
//   "Brogden (0470)",
//   "Charmany Instructional (0011)",
//   "CSC-Clinical Science Center (1400)",
//   "Harlow (0527)",
//   "Integrative Biology Research (0401)",
//   "Livestock Lab (0115)",
//   "Microbial (0060)",
//   "MSC-Medical Sciences Center (0450)",
//   "NutSci-Nutritional Sciences (0449)",
//   "PRL-Poultry Lab (0110)",
//   "Rennebohm (0034)",
//   "Robert P Hanson Biomedical Sciences Laboratories (0094)",
//   "Russell Lab (0114)",
//   "SMI Bardeen (0451)",
//   "SVM-Veterinary Medicine (0093)",
//   "Waisman Center	(0459)",
//   "Water Science and Engineering Laboratory (0403)",
//   "WIMR (1485)",
//   "Wisconsin Institute of Discovery (0213)",
//   "WisPIC-Wisconsin Psychiatric Institute And Clinics (0456)"
// ]

export default [
  { name: "Ansci-Animal Sciences", code: "0118" },
  { name: "Arlington ARS Swine SRTC", code: "0883" },
  { name: "Biochemical Sciences", code: "0204" },
  { name: "Biochemistry Addition (BABS)", code: "0205" },
  { name: "Biotech", code: "0082" },
  { name: "Biotron", code: "0045" },
  { name: "Birge", code: "0054" },
  { name: "Bock Lab", code: "0033" },
  { name: "Brogden", code: "0470" },
  { name: "Charmany Instructional", code: "0011" },
  { name: "CSC-Clinical Science Center", code: "1400" },
  { name: "Engineering Centers Building", code: "0481" },
  { name: "Enzyme Institute", code: "0479" },
  { name: "Harlow", code: "0527" },
  { name: "Integrative Biology Research", code: "0401" },
  { name: "IRI-Influenza Research Institute", code: "8128" },
  { name: "Livestock Lab", code: "0115" },
  { name: "McArdle", code: "0468" },
  { name: "Microbial", code: "0060" },
  { name: "MSC-Medical Sciences Center", code: "0450" },
  { name: "NutSci-Nutritional Sciences", code: "0449" },
  { name: "PRL-Poultry Lab", code: "0110" },
  { name: "Rennebohm", code: "0034" },
  { name: "Robert P Hanson Biomedical Sciences Laboratories", code: "0094" },
  { name: "Russell Lab", code: "0114" },
  { name: "SMI Bardeen", code: "0451" },
  { name: "SVM-Veterinary Medicine", code: "0093" },
  { name: "VA", code: "VA" },
  { name: "Waisman Center", code: "0459" },
  { name: "Water Science and Engineering Laboratory", code: "0403" },
  { name: "WIMR", code: "1485" },
  { name: "Wisconsin Institute of Discovery", code: "0213" },
  { name: "WisPIC-Wisconsin Psychiatric Institute And Clinic", code: "0456" },
  { name: "WisPIC-Wisconsin Psychiatric Institute And Clinics", code: "0456" },
]