import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { getServerUrl } from './utils';
// import { InMemoryCache } from 'apollo-cache-inmemory'
// import { createUploadLink } from 'apollo-upload-client'

import './App.css';

import { ThemeProvider } from '@mui/material/styles';

import Navbar from './components/Navbar';
import AnimalOrderForm from './components/forms/AnimalOrderForm';
import InternalTransferForm from './components/forms/InternalTransferForm';
import ImportForm from './components/forms/ImportForm';
import ExportForm from './components/forms/ExportForm';
import Home from './components/Home';
import Auth from './components/Auth';
import legacySARs from './components/Animal Reports/LegacySARs';
import legacyDARs from './components/Animal Reports/LegacyDARs';
import Login from './components/Login';
import NotAuthenticated from './components/NotAuthenticated';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import InventoryItemsTable from './components/InventoryItemsTable';
import SpecialConditionsLookup from './components/forms/SpecialConditionsLookup';
import InventorySelectionForm from './components/forms/InventorySelectionForm';
import InventoryOrdersTable from './components/InventoryOrdersTable';
import InventoryOrder from './components/InventoryOrder';
import FastRequestForm from './components/forms/FastRequestForm';
import { Dev } from './components/Dev';

import FastRequestsTable from './components/FastRequestsTable';
import FastRequest from './components/FastRequest';

import APIKeyForm from './components/forms/APIKeyForm';
import EmergencyAlertForm from './components/forms/EmergencyAlertForm';
import FastRequestTableDisplayView from './components/FastRequestTableDisplayView';

import Facilities from './components/Facilities/Facilities';
import SDSLinks from './components/SDSLinks';
import AddSDSLink from './components/forms/AddSDSLink';
import ResourceScheduler from './components/ResourceScheduler';
import UpdateArrow from './components/UpdateArrow';
import UserNotifications from './components/UserNotifications';
import NotificationAdmin from './components/NotificationAdmin';
import AddResourceForm from './components/forms/AddResourceForm';
import VCTDashboard from './components/VCT/VCTDashboard';
import VCTReporting from './components/Reporting/VCT/VCTReporting';
import CustomNotifications from './components/CustomNotifications';
import CustomNotificationConditionForm from './components/forms/CustomNotificationCondition';
import NoMatch from './components/NoMatch';
import SDAReporting from './components/Reporting/SAR/SDAReporting';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { theme } from './Theme';

import SiteAlert from './components/SiteAlert';

const defaultClient = new ApolloClient({
  link: createHttpLink({
    uri: getServerUrl(),
  }),
  cache: new InMemoryCache({
    dataIdFromObject: (o) => JSON.stringify(o),
  }),
});

function App() {
  const [client, setClient] = useState();
  const [authenticated, setAuthenticated] = useState();
  const [authorized, setAuthorized] = useState();
  const [user, setUser] = useState();
  const [authInitialized, setAuthInitialized] = useState(false);
  const [siteAlert, setSiteAlert] = useState(true);

  const onAuth = ({ authorized, authenticated, client, user }) => {
    setAuthorized(authorized);
    setAuthenticated(authenticated);
    setClient(client);
    setUser(user);
    setAuthInitialized(true);
  };

  const resetState = () => {
    setAuthorized();
    setAuthenticated();
    setClient();
    setUser();
    setAuthInitialized();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      const token = localStorage.getItem('rmaToken');
      if (authInitialized && !token) {
        // Force auth check if token is missing
        // Prevents preserved session after logout in separate tab
        resetState();
      }
    }, 1000);

    return () => clearInterval(intervalId); //This is important
  }, [authInitialized]);

  const logout = () => {
    localStorage.removeItem('rmaToken');
    localStorage.removeItem('shib_idp_session_ss');
    localStorage.removeItem('sessionExpiration');
    resetState();
    window.location.href = 'https://login.wisc.edu/logout';
  };

  const privateRouteProps = {
    authenticated,
    authorized,
    authInitialized,
    user,
    onAuth,
    resetState,
  };
  return (
    <ApolloProvider client={client ? client : defaultClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <div className="App">
              <Navbar user={user} authorizedGroups={['admin', 'superUser', 'husbandrySuperuser']} logout={logout} />
              <SiteAlert open={siteAlert} setOpen={setSiteAlert} />

              <Switch>
                <Route exact path="/Login" component={Login} />
                <Route exact path="/auth" children={<Auth onAuth={onAuth} />} />
                <Route exact path="/auth/:token" children={<Auth onAuth={onAuth} />} />
                <Route path="/authentication-required" component={NotAuthenticated} />
                <Route path="/public-sds" component={SDSLinks} />
                {/* <PrivateRoute
                {...privateRouteProps}
                authorizedGroups={['admin', 'superuser', 'husbandrySuperuser', 'brmsSuperuser']}
                exact
                path="/admin"
                component={Admin}
              /> */}
                <PrivateRoute {...privateRouteProps} exact path="/" component={Home} />
                <PrivateRoute {...privateRouteProps} path="/animal-order" component={AnimalOrderForm} />
                <PrivateRoute {...privateRouteProps} path="/internal-transfer" component={InternalTransferForm} />
                <PrivateRoute {...privateRouteProps} path="/import" component={ImportForm} />
                <PrivateRoute {...privateRouteProps} path="/export" component={ExportForm} />
                <PrivateRoute {...privateRouteProps} path="/request-fast" component={FastRequestForm} />
                <PrivateRoute {...privateRouteProps} path="/fast-requests" exact component={FastRequestsTable} />
                <PrivateRoute {...privateRouteProps} path="/fast-requests/:number" component={FastRequest} />
                <PrivateRoute {...privateRouteProps} exact path="/user-notifications" component={UserNotifications} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/user-notifications/unsubscribe/:protocolId"
                  component={UserNotifications}
                />
                <PrivateRoute {...privateRouteProps} path="/notification-admin" component={NotificationAdmin} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/legacy-sar"
                  authorizedGroups={['husbandrySuperuser', 'admin']}
                  component={legacySARs}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/legacy-dar"
                  authorizedGroups={['husbandrySuperuser', 'admin']}
                  component={legacyDARs}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/sar-reporting"
                  authorizedGroups={['admin']}
                  component={SDAReporting}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/inventory-items"
                  authorizedGroups={['brmsSuperuser', 'admin']}
                  component={InventoryItemsTable}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/custom-notifications"
                  authorizedGroups={['superuser', 'admin', 'brmsSuperuser']}
                  component={CustomNotifications}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/custom-notification-form"
                  authorizedGroups={['superuser', 'admin', 'brmsSuperuser']}
                  component={CustomNotificationConditionForm}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/order-inventory"
                  exact
                  authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                  component={InventorySelectionForm}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/inventory-orders"
                  exact
                  authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                  component={InventoryOrdersTable}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/inventory-orders/:number"
                  authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                  component={InventoryOrder}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/special-considerations"
                  authorizedGroups={['brmsSuperuser', 'admin', 'brms', 'husbandry']}
                  component={SpecialConditionsLookup}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/create-api-key"
                  authorizedGroups={['admin']}
                  component={APIKeyForm}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/emergency-alert"
                  authorizedGroups={['admin', 'brmsSuperuser']}
                  component={EmergencyAlertForm}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/fast-request-display"
                  authorizedGroups={['fastRequestReadOnly', 'admin']}
                  component={FastRequestTableDisplayView}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/facilities"
                  exact
                  authorizedGroups={['admin', 'facilitySupervisor', 'brmsSuperuser']}
                  component={Facilities}
                />
                <PrivateRoute {...privateRouteProps} path="/vct-dashboard" exact component={VCTDashboard} />
                <PrivateRoute {...privateRouteProps} path="/vct-dashboard/:vcrId" exact component={VCTDashboard} />
                <PrivateRoute {...privateRouteProps} path="/vct-dashboard/?form" exact component={VCTDashboard} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/vct-reporting"
                  exact
                  authorizedGroups={['admin']}
                  component={VCTReporting}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/add-sds"
                  exact
                  authorizedGroups={['brmsSuperuser', 'admin']}
                  component={AddSDSLink}
                />
                <PrivateRoute {...privateRouteProps} path="/sds" component={SDSLinks} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/add-resource"
                  authorizedGroups={['brmsSuperuser', 'admin']}
                  component={AddResourceForm}
                />
                <PrivateRoute {...privateRouteProps} exact path="/resource-scheduler" component={ResourceScheduler} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/resource-scheduler/:resourceId"
                  component={ResourceScheduler}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/update-arrow"
                  exact
                  authorizedGroups={['admin']}
                  component={UpdateArrow}
                />
                {process.env.NODE_ENV === 'development' && <Route path="/dev" component={Dev} />}
                <Route path="*" component={NoMatch} />
              </Switch>
              <Footer />
            </div>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

console.log(process.env.NODE_ENV);
export default App;
