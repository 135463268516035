import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_VCRS, CREATE_ERROR } from './graphql';

import { exportCSV, getBuildings } from '../Components/utils';

import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  ToggleButton,
  Collapse,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { KeyboardArrowRight, ExpandMore } from '@mui/icons-material';

import StandardWrapper from '../../design/StandardWrapper';
import { ReportsByProtocol, ResolutionsByProtocol, ResolutionComparison, ReasonComparison } from './Charts';

const VCTReporting = ({ user }) => {
  const DEFAULT_FROM_DATE = '2024-01-01';

  /* STATE */
  // Data variables

  const [dateFrom, setDateFrom] = useState(moment(DEFAULT_FROM_DATE));
  const [dateTo, setDateTo] = useState(moment());
  const [rawData, setRawData] = useState([]);

  const [exportData, setExportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Chart Selector
  const [viewMenu, setViewMenu] = useState(false);
  const [viewFilter, setViewFilter] = useState([
    {
      value: 'vcrsByProtocol',
      label: 'VCRs by Protocol',
      checked: true,
      component: ReportsByProtocol,
    },
    {
      value: 'vcrResolutionsByProtocol',
      label: 'VCR Resolutions by Protocol',
      checked: true,
      component: ResolutionsByProtocol,
    },
    {
      value: 'vcrResolutionsComparison',
      label: 'VCR Resolution Comparison',
      checked: true,
      component: ResolutionComparison,
    },
    {
      value: 'ocrReasonComparison',
      label: 'OCR Reason Comparison',
      checked: true,
      component: ReasonComparison,
    },
  ]);
  const [numColumns, setNumColumns] = useState(2);
  const [filterMenu, setFilterMenu] = useState(false);
  const [buildingFilter, setBuildingFilter] = useState([]);

  const [screenSizeDialog, setScreenSizeDialog] = useState(window.innerWidth < 768);
  const [errorDialog, setErrorDialog] = useState(false);

  useEffect(() => {
    const filtered = rawData.filter((vcr) =>
      buildingFilter.find((building) => building.name === vcr.buildingName && building.checked)
    );
    setFilteredData(filtered);
    setExportData(filtered);
  }, [rawData, buildingFilter]);

  /* DATA QUERY */
  const [createError] = useMutation(CREATE_ERROR);

  const [getVCRs, { loading }] = useLazyQuery(GET_VCRS, {
    variables: { userId: user._id },
    onCompleted: ({ vcrs }) => {
      const ocrs = vcrs.filter((vcr) => vcr.type === 'Overcrowded Cage');
      setRawData(ocrs);

      setBuildingFilter(getBuildings(ocrs));
    },
    onError: (error) => {
      createError({
        variables: {
          PVI: user.PVI,
          action: 'getVCRs',
          error: error ? JSON.stringify(error) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
      console.log('Error getting VCRs', error);
      setErrorDialog(true);
    },
  });

  // Initial query
  useEffect(() => {
    getVCRs({
      variables: {
        createdAt: [0, moment().diff(dateFrom, 'days')],
      },
    });
  }, []);

  /* RENDER */
  return (
    <StandardWrapper xl>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Vivarium Compliance Tool Reporting</Typography>
        </Grid>

        <Grid item xs={12} container rowSpacing={2}>
          {/* Date Filters */}
          <Grid item xs={12} container justifyContent="space-between" spacing="1em" alignItems="center">
            <Box display="flex" justifyContent="space-between" width="100%">
              {/* <Grid item xs={12} sm={3} textAlign="left"> */}
              <Box display="flex" gap=".5em">
                <Button
                  size="small"
                  variant={!viewMenu ? 'contained' : 'outlined'}
                  onClick={() => {
                    setViewMenu((prev) => !prev);
                  }}
                  endIcon={viewMenu ? <ExpandMore /> : <KeyboardArrowRight />}
                >
                  Select Views
                </Button>

                <Button
                  size="small"
                  variant={!filterMenu ? 'contained' : 'outlined'}
                  onClick={() => {
                    setFilterMenu((prev) => !prev);
                  }}
                  endIcon={filterMenu ? <ExpandMore /> : <KeyboardArrowRight />}
                >
                  Filters
                </Button>
              </Box>
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={7} container justifyContent="center" gap=".5em" textAlign="center" wrap="nowrap"> */}
              <Box display="flex" gap=".5em">
                <DatePicker
                  label="From"
                  value={dateFrom}
                  disableFuture
                  onChange={setDateFrom}
                  renderInput={(params) => <TextField {...params} required size="small" sx={{ width: '20ch' }} />}
                />

                <DatePicker
                  label="To"
                  value={dateTo}
                  disableFuture
                  onChange={setDateTo}
                  renderInput={(params) => <TextField {...params} required size="small" sx={{ width: '20ch' }} />}
                />

                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    const fromInt = moment().diff(dateFrom, 'days');
                    const toInt = moment().diff(dateTo, 'days');

                    getVCRs({ variables: { createdAt: [toInt, fromInt] } });
                  }}
                >
                  Query
                </Button>
              </Box>

              {/* </Grid> */}
              {/* <Grid item xs={12} sm={2} justifyContent="right" textAlign="center" container> */}
              <Button
                size="small"
                onClick={() => exportCSV('vcrReports', exportData, dateFrom, dateTo)}
                variant="contained"
              >
                Export All
              </Button>
              {/* </Grid> */}
            </Box>
            {/* View Menu */}
            <Collapse in={viewMenu}>
              <Grid xs={12} item container textAlign="center" gap="0.5em">
                <Stack spacing=".5em" padding=".5em 1.5em">
                  <Typography style={{ fontSize: '1rem', textAlign: 'left' }}>Data Views:</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '.5em',
                      flexWrap: 'wrap',
                      paddingLeft: '1em',
                    }}
                  >
                    {viewFilter.map((view, i) => (
                      <FilterToggleButton
                        key={i}
                        value={view.value}
                        selected={view.checked}
                        onChange={() =>
                          setViewFilter((prev) =>
                            prev.map((item, index) => (index === i ? { ...item, checked: !item.checked } : item))
                          )
                        }
                        style={{
                          backgroundColor: view.checked ? '#C5050C14' : '#0000000A',
                          color: view.checked ? '#C5050C' : '#00000089',
                        }}
                        size="small"
                      >
                        {view.label}
                      </FilterToggleButton>
                    ))}
                  </Box>

                  {/* Number of columns */}
                  <Typography style={{ fontSize: '1rem', textAlign: 'left' }}>Number of Columns:</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '.5em',
                      flexWrap: 'wrap',
                      paddingLeft: '1em',
                    }}
                  >
                    <RadioGroup
                      value={numColumns}
                      onChange={(e, v) => {
                        setNumColumns(parseInt(v));
                      }}
                      row
                    >
                      <FormControlLabel value={1} control={<Radio size="small" />} label="1 Column" />
                      <FormControlLabel value={2} control={<Radio size="small" />} label="2 Columns" />
                    </RadioGroup>
                  </Box>
                </Stack>
              </Grid>
            </Collapse>

            {/* Filter Menu */}
            <Collapse in={filterMenu}>
              <Grid xs={12} item container textAlign="center" gap="0.5em">
                <Stack spacing=".5em" padding=".5em 1.5em">
                  <Typography style={{ fontSize: '1rem', textAlign: 'left' }}>Buildings:</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '.5em',
                      flexWrap: 'wrap',
                      paddingLeft: '1em',
                    }}
                  >
                    {buildingFilter.map((building, i) => (
                      <FilterToggleButton
                        key={i}
                        value={building.name}
                        selected={building.checked}
                        onChange={() =>
                          setBuildingFilter((prev) =>
                            prev.map((item, index) => (index === i ? { ...item, checked: !item.checked } : item))
                          )
                        }
                        style={{
                          backgroundColor: building.checked ? '#C5050C14' : '#0000000A',
                          color: building.checked ? '#C5050C' : '#00000089',
                        }}
                        size="small"
                      >
                        {building.name}
                      </FilterToggleButton>
                    ))}
                  </Box>
                </Stack>
              </Grid>
            </Collapse>
          </Grid>

          {loading && <LinearProgress />}
          {viewFilter.map(
            (view, i) =>
              view.checked && (
                <Grid
                  item
                  xs={12}
                  md={numColumns === 2 ? 6 : 12}
                  container
                  justifyContent="center"
                  overflow="visible"
                  key={i}
                >
                  <view.component
                    data={filteredData}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    numcolumns={numColumns}
                    viewFilter={viewFilter}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Grid>
      {/* Screen Size Dialog */}
      <Dialog
        open={screenSizeDialog}
        onClose={() => {
          setScreenSizeDialog(false);
        }}
      >
        <DialogTitle>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This content is best viewed on a larger screen. Please use a desktop or laptop computer for the best
            experience.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setScreenSizeDialog(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* Error Dialog */}
      <Dialog
        open={errorDialog}
        onClose={() => {
          setErrorDialog(false);
        }}
      >
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            An error occurred while fetching the report data. Please try again or contact BRMS IT staff through a
            HelpDesk ticket.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setErrorDialog(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

function FilterToggleButton(props) {
  return <ToggleButton {...props} sx={{ paddingInline: '.5em', paddingBlock: '.25em', fontSize: '.75rem' }} />;
}

export default VCTReporting;
